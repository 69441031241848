<template>
  <div class="artworks">
    <swiper
      :slides-per-view="'auto'"
      :direction="'horizontal'"
      :nested="true"
      :preventClicks="true"
      :preloadImages="false"
      :resizeObserver="true"
      :resistanceRatio="0"
      :allowTouchMove="true"
      :pagination="{
        el: '.pagination',
        bulletClass: 'bullet',
        bulletActiveClass: 'active',
        clickable: true,
      }"
      :zoom="{
        maxRatio: 2.15,
        toggle: false,
        zoomedSlideClass: 'slide-zoomed',
      }"
      :effect="'fade'"
      :fadeEffect="{
        crossFade: true,
      }"
      :breakpoints="{
        769: {
          allowTouchMove: false,
        },
      }"
      @swiper="init"
      @slideChange="onSlideChange"
      @zoomChange="onZoom"
      @click="zoomOutHandler"
    >
      <swiper-slide
        v-for="(artwork, index) in artworks"
        :key="index"
        :data-id="index"
      >
        <div class="media" :class="{ zoomed: index == zoom }">
          <div class="swiper-zoom-container">
            <img
              class="swiper-lazy"
              draggable="false"
              :data-src="artwork"
              @click="zoomIn"
            />
            <div class="lazy-preloader"></div>
          </div>
        </div>
      </swiper-slide>
      <div class="pagination"></div>
    </swiper>
    <div class="details">
      <div class="caption">
        <p>
          <span v-html="title" />
          <span v-html="caption" />
        </p>
      </div>
      <div v-if="install" class="img-thumbs">
        <p><a @click="testing = !testing">Image Selection</a></p>
        <ul>
          <li
            v-for="(thumbnail, index) in artworkThumbs"
            :key="index"
            :class="{ active: currentImage == index }"
          >
            <button
              class="swiper-lazy"
              :data-background="thumbnail"
              v-on:click="selectImage(index)"
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SwiperCore, { Zoom, Pagination, EffectFade } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.min.css";
SwiperCore.use([Zoom, Pagination, EffectFade]);

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: [
    "dir",
    "file",
    "title",
    "caption",
    "duplicate",
    "isCurrent",
    "install",
  ],
  data() {
    return {
      carousel: null,
      currentImage: 0,
      ready: true,
      zoom: -1,
    };
  },
  computed: {
    ...mapGetters(["isDesktop", "isFullscreen"]),
    artworks() {
      let el = [this.dir + this.file];
      if (this.install) {
        el.push(this.dir + this.install.file);
      }
      return el;
    },
    artworkThumbs() {
      const prefix = this.dir + "th-";
      return this.install
        ? [prefix + this.duplicate, prefix + this.install.duplicate]
        : null;
    },
  },
  watch: {
    isFullscreen() {
      this.$nextTick(() => {
        this.carousel.update();
      });
    },
    isCurrent(val) {
      if (!val) this.zoomOutHandler();
    },
    isDesktop(val) {
      if (!val) this.zoomOut();
    },
  },
  methods: {
    init(swiper) {
      this.carousel = swiper;
    },
    onSlideChange(swiper) {
      this.currentImage = swiper.activeIndex;
    },
    onZoom(swiper, scale, imageEl, slideEl) {
      this.zoom = scale > 1 ? slideEl.getAttribute("data-id") : -1;
    },
    selectImage(index) {
      this.carousel.slideTo(index, 150);
    },
    zoomIn(e) {
      if (!this.isDesktop) return;
      if (this.carousel.zoom.scale == 1) {
        this.carousel.zoom.in(e);
      }
    },
    zoomOut() {
      if (this.carousel.zoom.scale > 1) {
        setTimeout(() => {
          this.carousel.zoom.out();
        }, 300);
      }
    },
    zoomOutHandler() {
      if (!this.isDesktop) return;
      this.zoomOut();
    },
  },
};
</script>

<style>
.artworks {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.artworks .swiper-container {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  width: 100%;
  padding: 6rem 3rem 0;
  box-sizing: border-box;
}

.artworks .swiper-wrapper {
  margin-bottom: 0;
  padding-left: 0;
  height: 0;
  flex: 1 1 auto;
}

.artworks .swiper-slide {
  display: flex;
  position: relative;
  overflow: hidden;
}

.artworks .swiper-slide:before {
  content: "";
  position: absolute;
}

.artworks .media {
  display: flex;
  width: 100%;
  height: 100%;
}

.artworks .pagination {
  position: static;
  padding: 6px 0;
  text-align: center;
  transition: 0.3s opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
}

.artworks .pagination span {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin: 0 4px;
  background-color: #ffffff;
  border: 1px solid #a0a0a0;
  border-radius: 100%;
  cursor: pointer;
}

.artworks .pagination span:only-child {
  display: none;
}

.artworks .pagination span.active {
  background-color: #ececec;
}

.artworks .details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3.75rem 3rem 0;
}

.artworks .details .caption span {
  display: block;
}

.artworks .details .img-thumbs {
  padding-top: 2.5rem;
  display: none;
}

.artworks .details .img-thumbs p {
  margin-bottom: 1.5rem;
  text-transform: uppercase;
}

.artworks .details .img-thumbs ul {
  display: block;
  list-style: none;
}

.artworks .details .img-thumbs ul li {
  position: relative;
  display: inline-block;
  width: 7.5rem;
  height: 7.5rem;
  margin-right: 0.5rem;
}

.artworks .details .img-thumbs ul li button {
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-size: 11.2rem;
  background-repeat: no-repeat;
  background-position: center;
  transition: opacity 0.15s ease-out;
  opacity: 0.5;
}

.artworks .details .img-thumbs ul li.active button {
  opacity: 1;
}

@media only screen and (min-width: 769px) {
  .artworks {
    flex-direction: row;
    padding: 6.25% 0;
  }

  .artworks .swiper-container {
    width: 60%;
    padding: 0 5rem;
    flex: initial;
    order: 2;
  }

  .artworks .media img {
    cursor: url(~@/assets/img/icons/cursor_zoom.png) 6 0, pointer;
  }

  .artworks .media.zoomed img {
    cursor: move !important;
  }

  .artworks .pagination {
    display: none;
  }

  .artworks .details {
    padding: 0 5rem;
    width: 40%;
    order: 1;
  }

  .artworks .details .img-thumbs {
    display: block;
  }

  .testing.artworks .swiper-wrapper {
    border: 1px solid #eee;
  }

  .testing.artworks .swiper-container {
    border-left: 1px solid #eee;
  }
}

@media only screen and (min-width: 1025px) {
  .artworks .swiper-container {
    padding: 0 9.6rem 0 0;
  }

  .artworks .details {
    padding: 0 5rem 0 9.6rem;
  }
}
</style>
